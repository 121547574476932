import $http from '@vue-util/http';
import { log } from './Logger';

export function get(url, data) { // eslint-disable-line  
  log(`Calling GET: ${url}`) //eslint-disable-line

  if (Array.isArray(url)) {
    // combine the results into a single object
    return Promise.all(url.map((u) => $http.get(u, data).then((res) => res.data)))
      .then((res) => res.reduce((acc, cur) => Object.assign(acc, cur), {}));
  }

  return $http.get(url, data).then((res) => res.data);
}

export function post(url, payload = {}, params = {}) {
  return $http.post(url, payload, params).then((res) => res.data);
}

export function deleteApi(url, config) { // eslint-disable-line  
  log(`Calling DELETE: ${url}`) //eslint-disable-line
  return $http.delete(url, config).then((res) => res.data);
}

export function put(url, payload = {}, params = {}) {
  return $http.put(url, payload, params).then((res) => res.data);
}
