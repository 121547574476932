import { SEARCH_PAGE_PREFIXES } from '../constants';

export default function getSearchPrefix(store) {
  const { isMcom } = store.state.envProps;
  const { killswitches } = store.state.navKillswitchStore;

  if (isMcom) {
    return SEARCH_PAGE_PREFIXES.featured;
  }

  return killswitches.useCommonSearchPageUrlPattern ? SEARCH_PAGE_PREFIXES.featured : SEARCH_PAGE_PREFIXES.keyword;
}
