import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { navigationStoreChangeListener } from '@common-vue/locator/src/utils/globalStoreChange';
import onPropertiesReady from '../events/onPropertiesReady';
import { FETCH_PREFERRED_STORE } from '../types/actions';

export default function useLocatorInit() {
  const store = useStore();
  const { hostlink } = store.state.envProps;
  store.state.envProps.locatorHostLink = hostlink;

  onPropertiesReady(() => {
    const { xMacysApiKey = '' } = store.state.navKillswitchStore.killswitches;

    store.state.envProps.xMacysApiKey = xMacysApiKey;
  }, store);
}

export function useLocationUpdate() {
  const store = useStore();
  const fetchPreferredStore = () => store.dispatch(`navPreferredStore/${FETCH_PREFERRED_STORE}`);

  onMounted(() => {
    navigationStoreChangeListener(fetchPreferredStore);
  });
}
