import { get } from '../../utils/api';
import useFetch from './useFetch';
import devProxyApiUrl from '../../utils/devProxyApiUrl';

function buildStoreUrl({ latLng, deviceType }) {
  const hasLatLng = latLng?.lat && latLng?.lng;
  const latLngParams = hasLatLng ? `?latitude=${latLng.lat}&longitude=${latLng.lng}&` : '?';

  return `/xapi/preferences/v1/stores/preferred${latLngParams}noOfStores=1&radius=M100&_deviceType=${deviceType}&_source=CSL`;
}

function buildSDDUrl({ zipCode }) {
  return `/api/store/v2/stores/shipTo/${zipCode}?_fields=locationNumber,sddMessage&shipMethod=SDD&httpMethod=GET`;
}

export default {};

export function usePreferredStore({ hostlink }) {
  const proxy = devProxyApiUrl(hostlink);

  function fetchPreferredStore({ latLng, deviceType, guid }) {
    const headers = { 'x-macys-userguid': guid };
    return useFetch(() => get(
      proxy(buildStoreUrl({ latLng, deviceType })),
      { headers },
    ));
  }

  function fetchSDDMessage({ zipCode }) {
    const headers = { 'X-Macys-ClientId': 'NavApp' };
    return useFetch(() => get(
      proxy(buildSDDUrl({ zipCode })),
      { headers },
    ));
  }

  return {
    fetchPreferredStore,
    fetchSDDMessage,
  };
}
