<template>
  <Link
    v-if="!isInternational"
    :href="Links.wishlist.url"
    :tracking="Links.wishlist.tracking"
    v-bind="$attrs"
    title="Wishlist"
    class="wishlist grid-x align-center align-middle"
  >
    <span class="heart-large" />
  </Link>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Links } from 'config';
import Link from './Link';

const store = useStore();
const isInternational = computed(() => store.state.pageData.navigation.context.isInternational);
</script>

<style scoped lang="scss">
.heart-large {
  transform: translate3d(0, 0, 0); // HFR-1045: hack for Safari to render correctly instead of pixelated background image
}
</style>

<style scoped lang="scss" brand="bcom">
@include breakpoint(medium down) {
  .heart-large {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
}
</style>
