export default function onAllPassedEventsReady(eventFunctions, finalCallback) {
  // Wrap each event function in a promise
  const eventPromises = eventFunctions.map((eventFunction) => new Promise((resolve) => {
    // Call the event function with a callback that resolves the promise
    eventFunction(resolve);
  }));

  // Use Promise.all to wait for all events to complete
  Promise.all(eventPromises).then(() => {
    // Once all events are complete, call the final callback
    finalCallback();
  });
}
