export function formatDateMcom(inputDate) {
  const parts = inputDate.split('-');

  if (parts.length !== 3) {
    return inputDate;
  }

  const year = parts[0].slice(2);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  return `${month}/${day}/${year}`;
}

export function formatDateBcom(inputDate) {
  const parts = inputDate.split('-');

  if (parts.length !== 3) {
    return inputDate;
  }

  const [yearFull, month, day] = parts;
  const year = yearFull.slice(2);

  return `${month}.${day}.${year}`;
}
