// This adds global logic to open windows in a new tab. Backbone pages may be still relying on this function being imported from header
// TODO Remove if this logic is not used outside of header/footer links
function windowPop(hostlink, u, n, f) {
  const isAbsoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i');
  let url = u;
  let name = 'default';
  let features = 'directories,location,menubar,resizable,scrollbars,status,toolbar';

  if (n) name = n;
  if (f) features = f;
  if (!isAbsoluteUrl.test(url)) url = hostlink + url;

  if (window) window.open(url, name, features).focus();
}

function addWindowPop(hostlink) {
  global.pop = (u, n, f) => windowPop(hostlink, u, n, f);
}

export default addWindowPop;
