import { onBeforeUnmount } from 'vue';

export default function useStoreModule(store, moduleName, module) {
  if (!store.hasModule(moduleName)) {
    store.registerModule(moduleName, module);
  }

  onBeforeUnmount(() => {
    if (store.hasModule(moduleName)) {
      store.unregisterModule(moduleName);
    }
  });
}
