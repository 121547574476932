/**
 * Move focus to the next or previous element
 * @param {Element} element parent element
 * @param {('next' | 'previous')} focusDirection direction to move focus
 * @param {boolean} cyclic whether to move focus to the first or last element when the end is reached
 */

export const moveFocusElement = (element, focusDirection, cyclic = false) => {
  if (!document.activeElement || !element) {
    return;
  }
  const focussableElements = Array.prototype.filter.call(
    element.querySelectorAll('a[href], button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'),
    (innerElement) => (
      innerElement.offsetWidth > 0
      || innerElement.offsetHeight > 0
      || innerElement.getClientRects().length > 0
    ),
  );

  const index = focussableElements.indexOf(document.activeElement);

  if (index === -1 && focusDirection === 'next') {
    focussableElements[0]?.focus();
    return;
  }
  if (cyclic && index === 0 && focusDirection === 'previous') {
    focussableElements.at(-1)?.focus();
    return;
  }
  const nextIndexRaw = focusDirection === 'next' ? index + 1 : index - 1;

  const nextIndex = cyclic
    ? nextIndexRaw % focussableElements.length
    : nextIndexRaw;

  focussableElements[nextIndex]?.focus();
};

/**
 * Add focus on element by id
 * @param {string} id id of html element
 */

export const getFocusById = (id) => {
  document.getElementById(id)?.focus();
};

/**
 * Add focus on element by css selector
 * @param {string} selector css selector of html element
 */

export const getFocusBySelector = (selector) => {
  document.querySelector(selector)?.focus();
};

export default {
  moveFocusElement,
  getFocusById,
  getFocusBySelector,
};
