import { Cookie } from '@common-vue/util/src/modules/cookie';

export default {};

export function useBag() {
  function getBagCountCookie() {
    return parseInt(Cookie.get('CartItem', 'GCs'), 10) || 0;
  }

  return {
    getBagCountCookie,
  };
}
