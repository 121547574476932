/* eslint-disable no-shadow */
/* istanbul ignore file */
import { SET_REGISTRIES } from '../../types/mutations';
import { formatDateMcom, formatDateBcom } from '../../utils/formatDate';

export function state() {
  return {
    registries: [],
    allowedOccasions: '',
  };
}

export const mutations = {
  [SET_REGISTRIES](state, payload = {}) {
    const { registries = [], allowedOccasions = '' } = payload;
    state.registries = registries;
    state.allowedOccasions = allowedOccasions;
  },
};

export const getters = {
  hasRegestries(state) {
    return !!state.registries.length;
  },
  occasionsMap(state) {
    return state.registries.reduce((acc, cur) => {
      const { occasionType } = cur;
      const prevValue = acc[occasionType];
      const newValue = prevValue ? prevValue + 1 : 1;

      return {
        ...acc,
        [occasionType]: newValue,
      };
    }, {});
  },
  occasions(state, getters, rootState) {
    const { occasionsMap } = getters;

    return state.registries.reduce((acc, cur) => {
      const {
        occasionType, occasionDate, registryId, registryGUID,
      } = cur;
      const date = rootState.envProps.isMcom ? formatDateMcom(occasionDate) : formatDateBcom(occasionDate);
      const text = occasionsMap[occasionType] > 1 || rootState.envProps.isBcom ? `${occasionType} ${date}` : occasionType;

      return acc.concat({ text, registryId, registryGUID });
    }, []);
  },
  allowedOccasions(state) {
    return state.allowedOccasions.toLowerCase().split(',').map((el) => el.trim());
  },
};
