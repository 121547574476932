function useScrollLock({ saveScrollPosition = false } = {}) {
  const lock = () => {
    if (typeof window === 'undefined') return;

    if (saveScrollPosition) {
      document.body.style.top = `-${window.scrollY}px`;
    }

    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  };

  const unlock = () => {
    if (typeof window === 'undefined') return;

    document.body.style.removeProperty('position');
    document.body.style.removeProperty('width');

    if (!saveScrollPosition) return;

    window.scrollTo(0, parseInt((document.body.style.top || '0'), 10) * -1);
    document.body.style.removeProperty('top');
  };

  return {
    lock,
    unlock,
  };
}

export default useScrollLock;
