/* eslint-disable no-shadow */
import { GET_IS_ACTIVE_OVERLAY, GET_OVERLAY_NAME } from '../../types/getters';
import { SET_ACTIVE_OVERLAY_NAME, RESET_OVERLAY } from '../../types/mutations';

import overlayNames from '../../constants/common/overlayNames';

export function state() {
  return {
    activeOverlayName: null,
  };
}

export const getters = {
  [GET_IS_ACTIVE_OVERLAY](state) {
    return state.activeOverlayName !== null;
  },
  [GET_OVERLAY_NAME](state) {
    return state.activeOverlayName;
  },
};

export const mutations = {
  [SET_ACTIVE_OVERLAY_NAME](state, name) {
    if (overlayNames.includes(name)) {
      state.activeOverlayName = name;
    } else {
      throw new Error(`Invalid overlay name: ${name}`);
    }
  },

  [RESET_OVERLAY](state) {
    state.activeOverlayName = null;
  },
};
