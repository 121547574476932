// TODO add documentation
/**
 * Returns instance of URLSearchParams WebApi (https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams)
 * @param {String} str - Optional: defaults to window.location, override to be used for unit testing and as needed
 * @returns
 */
const getURLQueryParams = (str = window.location.search) => new URLSearchParams(str);

const getURLParam = (key, search) => {
  const searchParams = getURLQueryParams(search);
  return searchParams.get(key);
};

/**
 *
 * @param {*} key - key value of query param
 * @param {Function} fn  - callback function to update query param, passes in existing param value
 * @param {String} search - DO NOT pass if you want page url params
 * @returns
 */
const updateURLParam = (key, fn, search) => {
  const searchParams = getURLQueryParams(search);
  const currentVal = searchParams.get(key);

  searchParams.set(key, fn(currentVal));
  return searchParams;
};

const addQueryParamsToUrl = (url = '', params) => {
  const queryParams = Object.entries(params).reduce((acc, [key, value = ''], index) => {
    const item = `${key}=${encodeURIComponent(value)}`;
    const sign = index > 0 ? '&' : '';

    return `${acc}${sign}${item}`;
  }, '');
  const hasQueryParams = url.includes('?');
  const sign = hasQueryParams ? '&' : '?';

  return `${url}${sign}${queryParams}`;
};

const goToUrl = (url) => window.location.assign(url);

module.exports = {
  updateURLParam, getURLParam, addQueryParamsToUrl, goToUrl,
};
