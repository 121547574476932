import { useStore } from 'vuex';

import * as OverlayType from '../../constants/common/overlayNames'; // Wipe out
import { SET_ACTIVE_OVERLAY_NAME, RESET_OVERLAY } from '../../types/mutations'; // Wipe out

/**
 * Use this composable to show/hide an overlay
 *
 * It takes overlayName (enum) as a parameter
 * @param {keyof Omit<OverlayType, 'default'>} overlayType the overlay name
 * @param {Object} options
 * @param {boolean} [options.force] force show/hide the overlay
 */
export const useOverlay = (overlayType, { force } = { force: false }) => {
  // TODO: Use provide/inject to pass overlayName to child components instead of using store
  const store = useStore();

  const setMutationName = `navOverlay/${SET_ACTIVE_OVERLAY_NAME}`;
  const resetMutationName = `navOverlay/${RESET_OVERLAY}`;
  const show = () => {
    store.commit(setMutationName, OverlayType[overlayType]);
  };
  const hide = () => {
    // If force is true, don't reset the overlay
    if (force) {
      return;
    }
    store.commit(resetMutationName);
  };

  return { show, hide };
};

export default useOverlay;
