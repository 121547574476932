import { PAY_BILL_LINK_TEXT_VARIATIONS } from '../constants';

export const handlePayYourCreditCardLinkClick = (event) => { //eslint-disable-line
  if (PAY_BILL_LINK_TEXT_VARIATIONS.includes(event.target.textContent.toLowerCase())) {
    event.preventDefault();
    fetch('/account-xapi/api/creditservice/paybill')
      .then((res) => res.json())
      .then((res) => {
        window.location.href = res.CreditCommon.returnURI;
      });
  }
};
