import { moveFocusElement } from '../../utils/accessibility/navigation';
import { getSiblingElement } from '../../utils/accessibility/getSiblingElement';

export const useFlyout = () => {
  const onKeydownArrowDown = ({ currentTarget }) => {
    moveFocusElement(currentTarget, 'next', true);
  };

  const onKeydownArrowUp = ({ currentTarget }) => {
    moveFocusElement(currentTarget, 'previous', true);
  };

  const onKeydownTab = ({ currentTarget }) => {
    moveFocusElement(currentTarget, 'next', true);
  };

  const onKeydownArrowRight = ({ target }, fobId) => {
    const nextGroup = getSiblingElement(document.getElementById(fobId), target, 'li.category-group', 'next');

    if (nextGroup) {
      nextGroup.querySelector('a').focus();
    }
  };

  const onKeydownArrowLeft = ({ target }, fobId) => {
    const previousGroup = getSiblingElement(document.getElementById(fobId), target, 'li.category-group', 'prev');

    if (previousGroup) {
      previousGroup.querySelector('a').focus();
    }
  };

  return {
    onKeydownArrowDown,
    onKeydownArrowUp,
    onKeydownArrowRight,
    onKeydownArrowLeft,
    onKeydownTab,
  };
};

export default useFlyout;
