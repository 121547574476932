import { ref, onMounted, onUnmounted } from 'vue';

/**
 * Focus element composables
 *
 * Provides a reactive focused value that is true when the element is focused
 * It alternative implementation of useFocus from @vueuse/core
 * @param {import('vue').Ref<HTMLElement | null>} element
 * @param {object} [options]
 * @param {boolean} [options.isForRelated=false] - If true, the focus is related to the element
 * @param {boolean} [options.initialState=false] - The initial state of the focus
 * @returns {{ focused: import('vue').Ref<boolean>, stop: () => void }}
 */
export const useFocusRelated = (element, {
  isForRelated = false,
  initialState = false,
} = { isForRelated: false, initialState: false }) => {
  const focused = ref(initialState);

  const focus = () => {
    focused.value = true;
  };
  const blur = () => {
    focused.value = false;
  };

  const onFocus = () => {
    focus();
  };

  /**
   * On blur handler
   * @param {FocusEvent} event
   */
  const onFocusOut = (event) => {
    if (isForRelated) {
      const { relatedTarget } = event;
      if (relatedTarget && element.value?.contains(relatedTarget)) {
        return;
      }
    }
    blur();
  };

  const start = () => {
    element?.value?.addEventListener('focusin', onFocus);
    element?.value?.addEventListener('focusout', onFocusOut);
  };

  const stop = () => {
    element?.value?.removeEventListener('focusin', onFocus);
    element?.value?.removeEventListener('focusout', onFocusOut);
  };

  onMounted(start);
  onUnmounted(stop);

  return { focused, stop };
};

export default useFocusRelated;
