import { Cookie } from '@common-vue/util/src/modules/cookie';

export default function getVisitorId() {
  const cookieVal = Cookie.get('AMCV_8D0867C25245AE650A490D4C@AdobeOrg');
  if (cookieVal && cookieVal.split('|')) {
    const unparsedCookieArray = cookieVal.split('|');
    const positionOfMcmid = unparsedCookieArray.indexOf('MCMID');
    const positionOfVisitorId = positionOfMcmid + 1;
    const visitorId = unparsedCookieArray[positionOfVisitorId];
    return visitorId;
  }
  return '';
}
