<template>
  <div
    ref="skipToContent"
    class="skip-to-content"
    :class="{'skip-to-content--hidden': !isShown}"
  >
    <div class="grid-container padding-bottom-xs padding-top-xs">
      <button
        id="skipToContentBtn"
        class="small"
        @keydown="onKeyDown"
        @click="focusOnMainContent"
        @focus="onFocus"
        @blur="onBlur"
      >
        Skip to main content
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { SET_SKIP_CONTENT_OFFSET } from '../../types/mutations';
import eventCodes from '../../constants/eventCodes';

const store = useStore();
const mainContentAreaSelector = '#mainContentArea';
const skipToContent = ref(null);
const isShown = ref(false);

function focusOnMainContent(event) {
  event.preventDefault();
  const $mainContentArea = document.querySelector(mainContentAreaSelector);

  $mainContentArea.classList.remove('hide');
  $mainContentArea.focus();

  nextTick(() => {
    $mainContentArea.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  });
}

function setGlobalOffset() {
  nextTick(() => {
    const { height } = skipToContent.value.getBoundingClientRect();

    store.commit(`headerStore/${SET_SKIP_CONTENT_OFFSET}`, height);
  });
}

function onKeyDown(event) {
  const { keyCode } = event;

  if (keyCode === eventCodes.ENTER || keyCode === eventCodes.SPACE) {
    focusOnMainContent(event);
  }
}

function onFocus() {
  isShown.value = true;
  setGlobalOffset();
}

function onBlur() {
  isShown.value = false;
  setGlobalOffset();
}
</script>

<style lang="scss">
.skip-to-content {
  background-color: $white;
  border-bottom: 1px solid $gray-4-color;

  &--hidden {
    height: 0;
    border: none;
    opacity: 0;
    overflow: hidden;
  }
}

#skipToContentBtn {
  text-decoration: underline;
  cursor: pointer;
}
</style>
