export const getSiblingElement = (wrapper, elem, selector, direction) => {
  let currentIndex;
  const allMatches = Array.from(wrapper.querySelectorAll(selector));

  allMatches.forEach((group) => {
    if (group.contains(elem)) {
      currentIndex = allMatches.indexOf(group);
    }
  });

  if (currentIndex === -1) return null;

  // If direction is 'next' and there is a next element in the array
  if (direction === 'next' && currentIndex < allMatches.length - 1) {
    return allMatches[currentIndex + 1];
  }

  // If direction is 'prev' and there is a previous element in the array
  if (direction === 'prev' && currentIndex > 0) {
    return allMatches[currentIndex - 1];
  }

  // If there is no sibling in the desired direction
  return null;
};

export default getSiblingElement;
