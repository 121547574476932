export default function addQueryParam(url, paramsToAdd) {
  const urlParts = url.split('?');
  const query = urlParts[1];
  let params = {};

  if (query) {
    params = query
      .split('&')
      .reduce((acc, cur) => {
        const [key, val] = cur.split('=');
        acc[key] = val;
        return acc;
      }, {});
  }

  Object
    .entries(paramsToAdd)
    .forEach(([key, value]) => {
      if (value !== '') {
        params[key] = value;
      }
    });

  const paramsString = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');

  return paramsString ? `${urlParts[0]}?${paramsString}` : url;
}
