/* eslint-disable no-shadow */
import {
  GET_IS_HAVE_SUGGESTIONS,
} from '../../types/getters';
import {
  FETCH_SEARCH_SUGGESTIONS,
} from '../../types/actions';
import {
  SET_SEARCH_SUGGESTION_DATA,
  RESET_SEARCH_SUGGESTION_DATA,
  SET_TOP_SUGGESTION,
} from '../../types/mutations';
import { viewTypeToDeviceType } from '../../constants';

import useSearchSuggestions from '../../composables/dumb/useSearchSuggestions';
import { securityValidation } from '../../utils/StringUtils';

const MIN_SEARCH_VALUE_LENGTH = 2;

const {
  getSuggestions,
  parseSuggestions,
  getFromCache,
  getDepartmentFromCache,
} = useSearchSuggestions();

export function state() {
  return {
    data: [],
    topSuggestion: null,
  };
}

export const mutations = {
  [SET_SEARCH_SUGGESTION_DATA](state, payload) {
    state.data = payload;
    state.topSuggestion = payload.length ? payload[0].sourceLabel : null;
  },

  [RESET_SEARCH_SUGGESTION_DATA](state) {
    if (state.data.length > 0) {
      state.data = [];
      state.topSuggestion = null;
    }
  },
  [SET_TOP_SUGGESTION](state, payload) {
    state.topSuggestion = payload;
  },
};

export const getters = {
  [GET_IS_HAVE_SUGGESTIONS](state) {
    return state.data.length !== 0;
  },
};

export const actions = {
  async [FETCH_SEARCH_SUGGESTIONS]({ commit, rootState }, value) {
    const { isInternational } = rootState.pageData.navigation.context; // international does not have search suggestions

    if (value.length < MIN_SEARCH_VALUE_LENGTH || !securityValidation(value) || isInternational) {
      commit(RESET_SEARCH_SUGGESTION_DATA);
      return;
    }
    const suggestionsFromCache = await getFromCache(value);
    const suggestionDepartmentsFromCache = await getDepartmentFromCache(value);

    if (suggestionsFromCache.value) {
      commit(SET_SEARCH_SUGGESTION_DATA, parseSuggestions({
        SUGGESTION: decodeURI(suggestionsFromCache.value).split(','),
        SuggestionWithDepartments: suggestionDepartmentsFromCache.value && JSON.parse(suggestionDepartmentsFromCache.value),
      }, value));
    } else {
      const { isMcom, hostlink } = rootState.envProps;
      const { regionCode } = rootState.pageData.navigation.context;
      const { viewType } = rootState.navViewTypeStore;
      const deviceType = viewTypeToDeviceType[viewType];

      const { fetch, result } = getSuggestions({
        value, deviceType, regionCode, isMcom, hostlink,
      });

      await fetch();

      const parsedSuggestions = parseSuggestions(result.value, value);
      commit(SET_SEARCH_SUGGESTION_DATA, parsedSuggestions);

      const { SUGGESTION, SuggestionWithDepartments } = result.value;
      suggestionsFromCache.value = encodeURI(SUGGESTION.toString());
      suggestionDepartmentsFromCache.value = SuggestionWithDepartments && JSON.stringify(SuggestionWithDepartments);
    }
  },
};
