import { fireLinkTag } from './tagManager';

export function tagBannerLinkClick(href) {
  if (!href) return;

  const evenName = 'nav click top ad';
  const [path] = href.split('?');
  const parts = path
    .split('/')
    .filter((part) => part)
    .reverse();

  const [last] = parts;
  const linkName = `glbheadernav_topad_${last}`;

  fireLinkTag({
    event_name: evenName,
    link_name: linkName,
  });
}

export default {
  tagBannerLinkClick,
};
