export default {
  2910: 'Featured Women\'s Clothing',
  16961: 'Featured Women\'s Shoes',
  16958: 'Featured Handbags',
  3376: 'Featured Jewelry & Accessories',
  2921: 'Featured Beauty Products',
  3864: 'Featured Men\'s Clothing & Accessories',
  3866: 'Featured Kids\' Clothing & Accessories',
  3865: 'Featured Home Items',
  3977: 'Featured Sale & Clearance',
  3948: 'Featured Gifts',
};
