/* eslint max-classes-per-file: ["error", 8] */
import ObjectUtil from '@core/util/object/index';

class Format {
  constructor(field) {
    this.field = field;
  }

  isNull() {
    return ['', null, undefined].indexOf(this.field) !== -1;
  }
}

class FormatString extends Format {
  transform() {
    return [String(this.field || '')];
  }
}

class FormatCurrency extends Format {
  getNumber() {
    if (typeof this.field === 'string') {
      const num = this.field.replace(/[,]+/g, '');
      return Number.isNaN(Number(num)) ? 0 : num;
    }
    return this.field || 0;
  }

  transform() {
    return [Number(this.getNumber()).toFixed(2).toString()];
  }
}

class FormatNumber extends Format {
  transform() {
    return [Number.parseInt(this.field || 0, 10).toString()];
  }
}

class FormatBoolean extends Format {
  getBool() {
    if (this.isNull()) {
      return '';
    } if (typeof this.field === 'boolean') {
      return this.field;
    } if (typeof this.field === 'string') {
      try {
        return typeof JSON.parse(this.field) === 'boolean' ? this.field : '';
      } catch (e) {
        return '';
      }
    } else {
      return '';
    }
  }

  transform() {
    return [String(this.getBool())];
  }
}

class FormatIsNotEmpty extends Format {
  getField() {
    return this.isNull() ? '' : this.field;
  }

  transform() {
    return [String(this.getField())];
  }
}

class FormatArray extends Format {
  getField() {
    if (this.isNull()) {
      return [''];
    }
    return Array.isArray(this.field) ? this.field.map((v) => String(v)) : [''];
  }

  transform() {
    return this.getField();
  }
}

class UnformatValue extends Format {
  getField() {
    if (this.field && this.field.constructor.name === 'Array') {
      return this.field.shift().toString();
    }
    return '';
  }

  transform() {
    return this.getField();
  }
}

export const STRING = 'string';
export const CURRENCY = 'currency';
export const NUMBER = 'number';
export const BOOL = 'bool';
export const NOT_EMPTY = 'notempty';
export const UNFORMAT = 'unformat';
export const ARRAY = 'array';

export function format(field, type = STRING) {
  switch (type) {
  case STRING:
    return new FormatString(field).transform();
  case CURRENCY:
    return new FormatCurrency(field).transform();
  case NUMBER:
    return new FormatNumber(field).transform();
  case BOOL:
    return new FormatBoolean(field).transform();
  case NOT_EMPTY:
    return new FormatIsNotEmpty(field).transform();
  case UNFORMAT:
    return new UnformatValue(field).transform();
  case ARRAY:
    return new FormatArray(field).transform();
  default:
    throw new Error('Format not available');
  }
}

/**
 * Given a product model, provides an object containing all price attributes in the expected analytics currency format.
 * @param {Object} productData product model containing price data to format.
 */
export const formatPrices = (productData) => {
  const priceArrays = {
    product_original_price: productData.product_original_price,
    product_price: productData.product_price,
    product_sub_total: productData.product_sub_total,
    order_sub_total: productData.order_sub_total,
  };
  const formattedPrices = {};
  Object.entries(priceArrays).forEach(([key, prices]) => {
    if (prices && prices.length > 0) {
      if (Array.isArray(prices)) {
        formattedPrices[key] = prices.map((price) => format(price, CURRENCY)[0] || '');
      } else {
        formattedPrices[key] = format(prices, CURRENCY)[0] || '';
      }
    } else if (key === 'order_sub_total') {
      formattedPrices[key] = '';
    } else {
      formattedPrices[key] = [''];
    }
  });
  return formattedPrices;
};

export const getDeliveryType = (product) => {
  const upcs = ObjectUtil.getChildProperty(product, 'relationships.upcs');
  // default scenario is product shipped from Macy's - supposedly there is a scenario where a product can be shipped
  // from Macy's or from vendor but I haven't found the data to support it.
  let deliveryType = 'own'; // this is the scenario when orderType === "POOL"
  if (upcs && upcs.length > 0) {
    const selectedProduct = upcs.find((p) => p.id === product.id);
    const orderType = ObjectUtil.getChildProperty(selectedProduct, 'availability.orderType');
    if (orderType === 'DROP') {
      // Product shipped from vendor.
      deliveryType = 'vendor direct';
    }
  }
  return deliveryType;
};
