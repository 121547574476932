import {
  ref, readonly, nextTick,
} from 'vue';
import { moveFocusElement } from '../../utils/accessibility/navigation';

const hasFlyout = (fob) => fob.children?.length;

export const useDesktopNavigation = ({
  initialActiveFobId = '',
} = {}) => {
  const activeFobId = ref(initialActiveFobId);

  const close = () => {
    activeFobId.value = '';
  };

  const open = (fob) => {
    if (hasFlyout(fob)) {
      activeFobId.value = fob.text;
    } else {
      close(); // closes any potentially open flyout when hovering on fob that doesnt have a flyout
    }
  };

  const isActive = (fobText) => activeFobId.value === fobText;

  const onToggleVisibility = async (fob) => {
    if (isActive(fob.text)) {
      close();
    } else {
      open(fob);

      await nextTick();

      const activeFlyout = document.getElementById(fob.text);
      moveFocusElement(activeFlyout, 'next');
    }
  };

  const onKeydownArrowRight = (navigationEl) => {
    close();
    moveFocusElement(navigationEl, 'next');
  };

  const onKeydownArrowLeft = (navigationEl) => {
    close();
    moveFocusElement(navigationEl, 'previous');
  };

  const moveFocusToCurrentFob = () => {
    const currentFob = document.getElementById(`fob-${activeFobId.value}`);
    currentFob?.focus();
  };

  return {
    activeFobId: readonly(activeFobId),
    open,
    close,
    isActive,
    onToggleVisibility,
    onKeydownArrowRight,
    onKeydownArrowLeft,
    moveFocusToCurrentFob,
  };
};

export default useDesktopNavigation;
