import { useSessionStorage } from '@vueuse/core';
import { get } from '../../utils/api';
import useFetch from './useFetch';
import getVisitorId from '../../utils/visitorId';

import searchSuggestionsConfig from '../../constants/searchSuggestions';
import devProxyApiUrl from '../../utils/devProxyApiUrl';

const escapeRawDataLabel = (item) => item.replace(/</g, '&lt;').replace(/>/g, '&gt;');

function buildUrl({
  value, deviceType, regionCode, isMcom,
}) {
  const val = encodeURIComponent(value);
  if (isMcom) {
    return `/suggester?prefix=${val}&maxSuggestions=${searchSuggestionsConfig.MAX_SUGGESTIONS}&bypass_redirect=yes&shippingCountry=${regionCode}&_deviceType=${deviceType}&store=MCOM&visitorId=${getVisitorId()}`;
  }
  return `/suggesterWithCat?prefix=${val}&maxSuggestions=${searchSuggestionsConfig.MAX_SUGGESTIONS}&bypass_redirect=yes&shippingCountry=${regionCode}&_deviceType=${deviceType}&store=bcom&visitorId=${getVisitorId()}`;
}

function highlightLabel(item, searchValue, department) {
  if (typeof item !== 'string' || typeof searchValue !== 'string') {
    throw new Error('AutoSuggestModel.highlightLabel: invalid data format for label or keyword');
  }

  if (department) {
    return `<span class='ui-keyword-highlight'>${escapeRawDataLabel(searchValue)}</span><span class='ui-keyword-department'>in ${department}</span>`;
  }

  const searchTerm = searchValue.toLowerCase().split(' ');
  const suggestion = escapeRawDataLabel(item).toLowerCase().split(' ');

  const result = [];
  const toHighlight = "<span class='ui-keyword-highlight'>term</span>";
  const lastTerm = searchTerm[searchTerm.length - 1];

  suggestion.forEach((token) => {
    if (searchTerm.includes(token)) {
      result.push(token);
    } else if ((token.indexOf(lastTerm) === 0) && (token.length > lastTerm.length)) {
      const highlightSubstring = token.substring(lastTerm.length, token.length);
      result.push(lastTerm + toHighlight.replace('term', highlightSubstring));
    } else {
      result.push(toHighlight.replace('term', token));
    }
  });

  return result.join(' ');
}

export default function useSearchSuggestions() {
  async function getFromCache(searchTerm) {
    return useSessionStorage(searchTerm, '');
  }

  async function getDepartmentFromCache(searchTerm) {
    return useSessionStorage(`department_${searchTerm}`, null);
  }

  function getSuggestions({
    value, deviceType, regionCode, isMcom, hostlink,
  }) {
    const proxy = devProxyApiUrl(hostlink);
    return useFetch(() => get(proxy(buildUrl({
      value, deviceType, regionCode, isMcom, hostlink,
    }))));
  }

  function parseSuggestions(response, searchValue) {
    const { SUGGESTION, SuggestionWithDepartments } = response;
    const suggestions = [];

    if (SuggestionWithDepartments) {
      SuggestionWithDepartments.forEach((suggestion) => {
        suggestion.departments.forEach((department) => {
          suggestions.push({
            sourceLabel: suggestion.name,
            label: highlightLabel(suggestion.name, suggestion.name, department.displayName),
            department: department.displayName,
          });
        });
      });
    }

    SUGGESTION.forEach((item) => {
      suggestions.push({
        label: highlightLabel(item, searchValue),
        sourceLabel: item,
      });
    });

    return suggestions;
  }

  return {
    getSuggestions,
    parseSuggestions,
    getFromCache,
    getDepartmentFromCache,
  };
}
