import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_IS_MOBILE_VIEW } from '../types/getters';

import useExperiments from './useExperiments';

export default function useFeatureEligibility() {
  const store = useStore();
  const experiments = useExperiments();

  const isMobile = computed(() => store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`]);

  const enhancedMobileNavFeature = computed(() => isMobile.value && experiments.value?.enhancedMobileNavEnabled);
  const enhancedDesktopNavFeature = computed(() => !isMobile.value && experiments.value?.enhancedDesktopNavEnabled);

  return {
    enhancedMobileNavFeature,
    enhancedDesktopNavFeature,
  };
}
