/* eslint-disable no-shadow */
import {
  SET_COUNT,
  INCREASE_COUNT,
} from '../../types/mutations';
import {
  GET_FORMATTED_COUNT,
  GET_IS_MORE_THAN_ONE,
  GET_IS_EMPTY,
} from '../../types/getters';
import { MAX_BAG_COUNT } from '../../constants';

export function state() {
  return {
    count: 0,
  };
}

export const getters = {
  [GET_FORMATTED_COUNT]: (state) => (state.count > MAX_BAG_COUNT ? `${MAX_BAG_COUNT}+` : String(state.count)),
  [GET_IS_MORE_THAN_ONE]: (state) => state.count > 1,
  [GET_IS_EMPTY]: (state) => state.count === 0,
};

export const mutations = {
  [SET_COUNT](state, count) {
    if (typeof count !== 'number') {
      throw new Error('Count must be a number');
    }

    state.count = count < 0 ? 0 : count;
  },

  [INCREASE_COUNT](state, count) {
    if (typeof count !== 'number') {
      throw new Error('Count must be a number');
    }

    state.count += count;
  },
};
