import {
  ref, readonly, watchEffect, computed,
} from 'vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';
import { moveFocusElement } from '../../utils/accessibility/navigation';
import { useMouseInElement } from '../ui/useMouseInElement';
import { useFocusRelated } from '../ui/useFocusRelated';

/**
 * A composable that exposes the logic and state for a dropdown
 * @param {object} options
 * @param {import('vue').Ref<HTMLElement | null>} options.header - The header element of the dropdown
 * @param {import('vue').Ref<HTMLElement | null>} options.content - The content element of the dropdown
 * @param {boolean} [options.initialState=false] - The initial state of the dropdown
 */
export const useDropdown = ({
  header = null,
  content = null,
  initialState = false,
} = {}) => {
  // Open state
  const opened = ref(initialState);
  // Open/Close/Toggle functions
  const open = () => { opened.value = true; };
  const close = () => { opened.value = false; };
  const toggle = () => { opened.value = !opened.value; };

  /**
   * On toggle dropdown keydown handler
   * @param {KeyboardEvent} event
   */
  const onKeydownToggle = (event) => {
    if (opened.value) {
      close();
      return;
    }
    if (!content?.value) {
      return;
    }
    event.preventDefault();
    open();
  };
  /**
   * On keydown of arrow down key handler
   * @param {KeyboardEvent} event
   */
  const onKeydownArrowDown = (event) => {
    if (!content.value) {
      return;
    }
    event.preventDefault();

    if (opened.value) {
      moveFocusElement(content.value, 'next');
      return;
    }
    open();
  };
  /**
   * On keydown of arrow up key handler
   */
  const onKeydownArrowUp = () => {
    if (opened.value) {
      moveFocusElement(content.value, 'previous');
    }
  };
  /**
   * On mouse in handler
   * @param {boolean} value
   */
  const onMouseIn = (value) => {
    if (!content?.value) {
      return;
    }
    if (!value) {
      close();
      return;
    }
    open();
  };

  onClickOutside(header, close);
  onKeyStroke('Escape', close);

  onKeyStroke(' ', onKeydownToggle, { target: header });
  onKeyStroke('ArrowDown', onKeydownArrowDown, { target: header });
  onKeyStroke('ArrowUp', onKeydownArrowUp, { target: header });
  useMouseInElement(header, onMouseIn);

  const { focused } = useFocusRelated(header, {
    isForRelated: true,
  });

  watchEffect(() => {
    if (!content?.value || !header?.value) {
      return;
    }
    if (!focused.value) {
      close();
    }
  });

  const active = computed(() => opened.value || focused.value);

  // Return the composable API
  return {
    opened: readonly(opened),
    active,
    open,
    close,
    toggle,
  };
};

export default useDropdown;
