/* istanbul ignore file */
// Ignoring test coverage since jest doesn't work well with focus
// Taken from https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
export function useFocusTrap(element) { // eslint-disable-line 

  function trap(e) {
    const focusableEls = element.value.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) /* shift + tab */ {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else /* tab */ if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      e.preventDefault();
    }
  }

  function activate() {
    if (element?.value?.addEventListener) {
      element.value.addEventListener('keydown', trap);
    }
  }

  function deactivate() {
    if (element?.value?.removeEventListener) {
      element.value.removeEventListener('keydown', trap);
    }
  }

  return { activate, deactivate };
}
