import experimentation from '../../features/experimentation/store';
import { SHOW_MINIMAL_SEARCH } from '../../types/getters';
import { NAV_KILLSWITCH_STORE } from '../../types/names';

const experimentGetters = {
  [SHOW_MINIMAL_SEARCH](state, getters, rootState) {
    const { killswitches } = rootState[NAV_KILLSWITCH_STORE];
    return state.ids.includes('1239-21') && killswitches.showMinimalSearch;
  },
};

export default {
  ...experimentation,
  getters: { ...experimentation.getters, ...experimentGetters },
};
