import { get } from '../../utils/api';
import useFetch from './useFetch';

function getUrl({ phrase, hostlink }) {
  const searchPhrase = phrase?.split(' ').join('%20');
  const protocol = hostlink.split(':')[0];
  const baseUrl = hostlink.substr(hostlink.indexOf('.'));

  // TODO remove once api is stable
  // if (process.env.NODE_ENV === 'development' || hostlink.includes('zeus.fds.com')) {
  if (process.env.NODE_ENV === 'development') {
    return `/development/pros?phrase=${searchPhrase}`;
  }
  return `${protocol}://api${baseUrl}/product/v1/suggest/public?searchPhrase=${searchPhrase}&_offset=1&_limit=5&imgWidth=200&imgQuality=90`;
}

export function useProsFetch({ phrase, hostlink }) {
  const headers = { 'X-Macys-ClientId': 'NavApp' };
  return useFetch(() => get(getUrl({ phrase, hostlink }), { headers }));
}

export default {};
