import { computed } from 'vue';
import { useStore } from 'vuex';
import { watchOnce } from '@vueuse/core';

import { NAV_KILLSWITCH_STORE } from '../types/names';

export default function onKsFlagsReady(cb) {
  const store = useStore();
  const isFlags = computed(() => store.state.navKillswitchStore.isFlags);

  if (isFlags.value) {
    cb();
    return;
  }

  watchOnce(isFlags, cb);
}

export async function onKsFlagsFetched(cb) {
  const store = useStore();

  // client side only
  if (typeof window !== 'undefined') {
    await store.dispatch(`${NAV_KILLSWITCH_STORE}/fetch`);
  }

  cb();
}
