import { ref, onMounted, onUnmounted } from 'vue';

/**
 * Mouse in element composable
 *
 * Provides a reactive isInside value that is true when the mouse is inside the element
 * It alternative implementation of useMouseInElement from @vueuse/core
 * @param {import('vue').Ref<HTMLElement | null>} element
 * @param { (value: boolean) => void } [handler]
 * @returns {{ isInside: import('vue').Ref<boolean>, stop: () => void }}
 */
export const useMouseInElement = (element, cb) => {
  const isInside = ref(false);

  const onMouseEnter = () => {
    isInside.value = true;
    cb?.(isInside.value);
  };

  const onMouseLeave = () => {
    isInside.value = false;
    cb?.(isInside.value);
  };

  const stop = () => {
    cb?.(isInside.value);
    element?.value?.removeEventListener('mouseenter', onMouseEnter);
    element?.value?.removeEventListener('mouseleave', onMouseLeave);
  };

  onMounted(() => {
    element?.value?.addEventListener('mouseenter', onMouseEnter);
    element?.value?.addEventListener('mouseleave', onMouseLeave);
  });

  onUnmounted(stop);

  return { isInside, stop };
};

export default useMouseInElement;
