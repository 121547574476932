import { moveFocusElement } from '../../utils/accessibility/navigation';

export const useSearchModalInput = ({
  suggestionsEl = null,
  historyEl = null,
} = {}) => {
  function onKeydownArrowDown() {
    const activeElement = suggestionsEl?.value?.listEl || historyEl?.value?.listEl;

    if (!activeElement) {
      return;
    }

    moveFocusElement(activeElement, 'next');
  }

  return {
    onKeydownArrowDown,
  };
};

export default useSearchModalInput;
