import { useStore } from 'vuex';

/**
 * Get the full asset image URL by combining the asset host and relative image path.
 *
 * @param {string} relativeImagePath - The relative path of the image.
 * @returns {string} The full URL of the asset image.
 */
const getAssetImage = (relativeImagePath) => {
  const store = useStore();
  const { headerFooterAssetHost } = store.state.envProps;

  return relativeImagePath
    ? `${headerFooterAssetHost}/app/navigation-wgl/static/images/${relativeImagePath}`
    : '';
};

export default getAssetImage;
