// Reminder: if we add the new attribute for the following pages (HP,PLP,PDP)
// we MUST update nav-wgl ver their, because the WGL middleware imports this file
module.exports = {
  _isMinimal: true,
  _isCheckout: true,
  _isPromoHiddenMew: true,
  _showMinimalSearch: true,
  _isPermanentSigninTooltip: true,
  _isDeals: true,
};
