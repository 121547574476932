export function mapAemFooterLink(linkItem, index) {
  const {
    heading,
    contentlinks,
  } = linkItem;
  const name = `SITE_FOOTER_COLUMN_${index + 1}`;

  return {
    name,
    heading,
    links: contentlinks.map(({ value: url, text: alt, tracking }) => ({ url, alt, tracking })),
  };
}

export function mapAemLegalLink(linkItem) {
  const {
    label: name,
    value: url,
    tracking,
    oneTrust,
  } = linkItem;

  return {
    name,
    url,
    tracking,
    oneTrust,
  };
}

export function filterAemLegalLink(linkItem) {
  const { label, oneTrust, value } = linkItem;

  return oneTrust ? label : label && value;
}

export function filterAemFooterLink(linkItem) {
  const {
    heading,
    contentlinks,
  } = linkItem;

  return heading && contentlinks?.length;
}
