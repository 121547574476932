import { useStore } from 'vuex';
import { useStorage } from '@vueuse/core';

import constants from '../../constants/mcom/searchHistory';

function normalizeValue(value) {
  return value.trim().toLowerCase();
}

function getKey() {
  return constants.key;
}

function removeDuplicates(savedData, secureValue) {
  const uppercaseJSON = savedData.map((v) => normalizeValue(v));

  const duplicateIndex = uppercaseJSON.indexOf(normalizeValue(secureValue));
  if (duplicateIndex !== -1) {
    savedData.splice(duplicateIndex, 1);
  }

  savedData.unshift(secureValue);

  return savedData;
}

function limitEntriesLength(arr) {
  return arr.slice(0, constants.maxItems);
}

export default function useSearchHistory() {
  const store = useStore();
  const key = getKey(store.state.pageData.navigation.context);
  const searchHistory = useStorage(key, []);

  function addToSearchHistory(value) {
    searchHistory.value = limitEntriesLength(removeDuplicates(searchHistory.value, value.trim()));
  }

  function clearSearchHistory() {
    searchHistory.value = [];
  }

  return {
    searchHistory,
    addToSearchHistory,
    clearSearchHistory,
  };
}
