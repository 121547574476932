<template>
  <div
    id="mainContentArea"
    ref="mainContentArea"
    data-testid="mainContentArea"
    tabindex="0"
    aria-label="main content"
    class="hide"
    @blur="onBlur"
  />
</template>

<script setup>
import { ref } from 'vue';

const mainContentArea = ref(null);

function onBlur() {
  mainContentArea.value?.classList.add('hide');
}
</script>
