<template>
  <Minimal
    v-if="isMinimal"
    :is-checkout="isCheckout"
  />
  <Responsive v-else />
</template>

<script setup>
/* istanbul ignore file */
import { defineAsyncComponent } from 'vue';

import Responsive from './footer/Responsive.mcom';

defineProps({ // eslint-disable-line no-undef
  isMinimal: {
    type: Boolean,
    default: false,
  },
  isCheckout: {
    type: Boolean,
    default: false,
  },
});

const Minimal = defineAsyncComponent(() => import(/* webpackChunkName: "Minimal" */ './footer/MinimalFooter.common'));
</script>
