export const QUICK_BAG_OVERLAY = 'quick-bag';
export const NAVIGATION_OVERLAY = 'navigation';
export const MOBILE_NAVIGATION_OVERLAY = 'mobile-navigation';
export const LINK_RAIL_OVERLAY = 'link-rail';
export const STORE_LOCATOR_OVERLAY = 'store-locator';
export const SEARCH_MODAL_OVERLAY = 'search-modal';

export default [
  LINK_RAIL_OVERLAY,
  QUICK_BAG_OVERLAY,
  NAVIGATION_OVERLAY,
  MOBILE_NAVIGATION_OVERLAY,
  STORE_LOCATOR_OVERLAY,
  SEARCH_MODAL_OVERLAY,
];
