import { computed } from 'vue';
import { useStore } from 'vuex';
import { watchOnce } from '@vueuse/core';

export default function onPropertiesReady(cb, _store) {
  const store = _store || useStore();
  const isProperties = computed(() => store.state.navKillswitchStore.isProperties);

  if (isProperties.value) {
    cb();
    return;
  }

  watchOnce(isProperties, cb);
}
