export default {};

export function useBackboneEvents() {
  const isBackboneAvailable = typeof window.Backbone !== 'undefined';

  const listenTo = (eventName, callback) => {
    if (isBackboneAvailable) {
      window.Backbone.listenTo(window.Backbone, eventName, callback);
    }
  };

  const stopListening = (eventName, callback) => {
    if (isBackboneAvailable) {
      window.Backbone.stopListening(window.Backbone, eventName, callback);
    }
  };

  const trigger = (eventName, payload) => {
    if (isBackboneAvailable) {
      window.Backbone.trigger(eventName, payload);
    }
  };

  return {
    listenTo,
    stopListening,
    trigger,
  };
}
