import { ref } from 'vue';
import { logError } from '../../utils/Logger';
import { ExpiresStorageProxy } from '../../utils/storage/expireStorageProxy';

function useFetch(getResult) {
  const result = ref(null);
  const isLoading = ref(false);
  const error = ref(null);

  async function fetch(...args) {
    isLoading.value = true;
    error.value = null;
    try {
      result.value = await getResult(...args);
    } catch (e) {
      logError(e?.message);
      error.value = e;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    result, isLoading, error, fetch,
  };
}

export function useFetchMemoized(getResult, { prefix = 'memo', expiresIn = 0, storage } = {}) {
  const fetchObject = useFetch(getResult);
  const $storage = storage || ExpiresStorageProxy(localStorage, { expiresIn });

  const { fetch } = fetchObject;
  Object.defineProperty(fetchObject, 'fetch', {
    value: async (...args) => {
      const key = prefix + JSON.stringify(args);
      const cached = $storage.getItem(key);
      if (cached) {
        fetchObject.result.value = JSON.parse(cached);
      } else {
        await fetch(...args);
        $storage.setItem(key, JSON.stringify(fetchObject.result.value));
      }
    },
  });

  return fetchObject;
}

export default useFetch;
