import { capitalizeFirstLetter } from './StringUtils';

export const formatProduct = ({
  product, quantity, bagItemId, giftCardEmail,
}) => {
  const result = { ...product };

  result.bagItemId = bagItemId;

  if (result.detail) {
    result.detail.quantity = quantity;
  }

  if (giftCardEmail) {
    result.detail.giftCardEmail = giftCardEmail;
  }

  if (result.imagery?.urlTemplate?.product) {
    result.imagery.urlTemplate = result.imagery?.urlTemplate?.product;
  }

  if (result.traits?.colors && result.traits?.colors?.selectedColor?.name) {
    result.traits.colors = [result.traits.colors.selectedColor.name];
  }

  if (result.traits?.size && result.traits?.size?.selectedSize?.name) {
    result.traits.sizes = [result.traits.size.selectedSize.name];
  }

  if (result.pricing?.price?.tieredPrice && result.pricing?.price?.tieredPrice?.length) {
    result.pricing.price.tieredPrice.forEach((element) => {
      element.values = [{
        value: element.value[0],
        type: element.type,
        formattedValue: element.formattedValue[0],
      }];
    });
  }

  return result;
};

export const deleteReviews = (product) => {
  if (product.product?.detail?.reviewStatistics) product.product.detail.reviewStatistics = {};
  if (product.detail?.reviewStatistics) product.detail.reviewStatistics = {};
  return product;
};

export const deleteName = (product) => {
  if (product.product?.detail?.name) product.product.detail.name = '';
  if (product.detail?.name) product.detail.name = '';
  return product;
};

export const extractBrandFromName = (name, brand) => name
  .toLowerCase()
  ?.split(brand.toLowerCase())[1]
  ?.trim()
  ?.split(' ')
  ?.map((word) => {
    if (word.includes('-')) {
      return word
        .split('-')
        .map((subword) => capitalizeFirstLetter(subword))
        .join('-');
    }
    return capitalizeFirstLetter(word);
  })
  ?.join(' ');

export const parseBrandAndName = (product) => {
  if (product.product.detail?.brandName) {
    product.product.detail.name = extractBrandFromName(
      product.product.detail.name,
      product.product.detail.brandName,
    );
    product.product.detail.brand = {
      name: capitalizeFirstLetter(product.product.detail.brandName),
    };
  }
  return product;
};
